
  import { Action, Getter } from "vuex-class";
  import { Component, Ref, Mixins } from "vue-property-decorator";
  import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormCheckbox, FormYesNo } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { bus } from "@/main";
  import { Partner, PartnerServiceAddon, PartnerType } from "@/store/modules/partner.store";
  import { CreatePartnerRequestPayload, PartnerRequestStatus } from "@/store/modules/partner-request.store";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { IndexDocumentPayload, DocumentType } from "@/store/modules/document.store";
  import { HasPickupKeys } from "@/mixins/partnerRequests/has-pickup-keys";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      Spinner: () => import("@/components/general/spinner.vue"),
      FormBase,
      FormInput,
      FormDate,
      FormYesNo,
      FormTextarea,
      FormSelect,
      FormError,
      FormCheckbox,
      FormSubmit,
    },
  })
  export default class AddPartnerServiceModal extends Mixins(HasFormErrors, HasPickupKeys) {
    @Getter("activity/viewing") activity!: Activity;

    @Action("partner-request/create") createPartnerRequest!: (payload: CreatePartnerRequestPayload) => void;
    @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

    @Ref() form!: FormClass;

    documents: DocumentType[] = [
      DocumentType.asbestosProjectAssignmentAgreement,
      DocumentType.asbestosProjectDocumentationConstructionPlans,
      DocumentType.asbestosProjectDocumentationOther,
      DocumentType.asbestosProjectDocumentationPostIntervention,
    ];

    show = false;

    partner: Partner | null = null;

    partnerTypes = PartnerType;

    uploadedDocuments: Document[] = [];

    loadingDocuments = false;

    payload: CreatePartnerRequestPayload = {
      partner_id: NaN,
      activity_id: NaN,
      planned_at: null,
      day_period: null,
      note: "",
      status: PartnerRequestStatus.new,
      services: [],
      pick_up_key: 0,
      pick_up_key_data: {
        phone: "",
        address: {
          street: "",
          number: "",
          bus: "",
          zip: "",
        },
      },
    };

    servicePayload: any = [];

    get matchedUploadedDocuments() {
      return this.uploadedDocuments.map((document) => document.type);
    }

    mounted() {
      bus.$off("show-add-partner-service");
      bus.$on("show-add-partner-service", (partner: Partner) => {
        this.show = true;

        this.partner = Object.assign({}, partner);

        this.payload.partner_id = partner.id;
        this.payload.activity_id = this.activity.id;
        this.payload.planned_at = "";
        this.payload.note = "";
        this.payload.services = [];
        this.servicePayload = [];

        (partner.services ?? []).forEach((service, index) => {
          this.servicePayload.push({
            ...service,
            enabled: this.isServiceEnabled(index),
            addons: service.addons?.map((addon) => {
              return {
                ...addon,
                enabled: false,
              };
            }),
          });
        });

        this.fetchDocuments();
      });
    }

    handleKeyContactChanged(payload: any) {
      this.payload.note = "";
      if (!payload.first_name && !payload.last_name && !payload.email && !payload.phone) {
        return;
      }

      let contactInfo = "Contactgegevens voor de sleutel: \n";
      contactInfo += `\n${payload.first_name} ${payload.last_name}`;
      if (payload.email) {
        contactInfo += `\n${payload.email}`;
      }

      if (payload.phone) {
        contactInfo += `\n${payload.phone}`;
      }

      this.payload.note = contactInfo;
    }

    async fetchDocuments() {
      if (!this.partner) {
        return;
      }

      if (!this.documents.length || ![this.partnerTypes.asbestosInspector].includes(this.partner.type)) {
        return;
      }

      this.loadingDocuments = true;

      this.uploadedDocuments = await this.indexDocuments({ per_page: 250, q: { and: { or: { type: this.documents }, activity_id: Number(this.$route.params.id) } } });

      this.loadingDocuments = false;
    }

    async submit(form: FormClass) {
      try {
        this.servicePayload.forEach((service: any) => {
          if (service.enabled) {
            let s: any = {
              title: service.title,
            };
            let a = service.addons
              ?.filter((addon: any) => addon.enabled)
              .map((addon: PartnerServiceAddon) => {
                return { title: addon.title };
              });

            if (a && a.length > 0) {
              s.addons = a;
            }
            this.payload.services.push(s);
          }
        });

        await this.createPartnerRequest(this.payload);

        this.handleClose();
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    isServiceEnabled(index: number) {
      if (this.partner?.type === PartnerType.photo) {
        return [0, 1].includes(index) ? true : false;
      }

      if (this.partner?.type === PartnerType.asbestosInspector) {
        return [0].includes(index) ? true : false;
      }

      return false;
    }

    handleClose() {
      this.clearErrors();

      this.show = false;
    }
  }
