import { Component, Vue } from "vue-property-decorator";
import { Mandate } from "@/store/modules/mandate.store";

export interface PickupKeyPayload {
  pick_up_key: number;
  pick_up_key_data?: {
    phone: string;
    address: {
      street: string;
      number: string;
      bus: string;
      zip: string;
    };
  };
}

@Component({
  components: {
    RequestKeyFormPart: () => import("@/components/modals/vge/parts/request-key-form-part.vue"),
    NoKeyFormPart: () => import("@/components/modals/vge/parts/no-key-form-part.vue"),
  },
})
export class HasPickupKeys extends Vue {
  payload: PickupKeyPayload = {
    pick_up_key: 1,
    pick_up_key_data: {
      phone: "",
      address: {
        street: "",
        number: "",
        bus: "",
        zip: "",
      },
    },
  };

  prefillKeysAvailable(mandate: Mandate | undefined) {
    if (!mandate) {
      return;
    }

    if (!mandate.details?.article_2_keys_available) {
      return;
    }

    this.payload.pick_up_key = mandate.details?.article_2_keys_available ? 1 : 0;
    if (mandate.details?.article_2_keys_available_holder_phone && this.payload.pick_up_key_data) {
      this.payload.pick_up_key_data.phone = mandate.details?.article_2_keys_available_holder_phone;
    }
  }
}
